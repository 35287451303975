exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-como-reservar-js": () => import("./../../../src/pages/como-reservar.js" /* webpackChunkName: "component---src-pages-como-reservar-js" */),
  "component---src-pages-eventos-especiais-js": () => import("./../../../src/pages/eventos-especiais.js" /* webpackChunkName: "component---src-pages-eventos-especiais-js" */),
  "component---src-pages-eventos-tpo-js": () => import("./../../../src/pages/eventos-tpo.js" /* webpackChunkName: "component---src-pages-eventos-tpo-js" */),
  "component---src-pages-fale-conosco-js": () => import("./../../../src/pages/fale-conosco.js" /* webpackChunkName: "component---src-pages-fale-conosco-js" */),
  "component---src-pages-franquia-4-beachgym-js": () => import("./../../../src/pages/franquia-4beachgym.js" /* webpackChunkName: "component---src-pages-franquia-4-beachgym-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-moving-bit-js": () => import("./../../../src/pages/moving-bit.js" /* webpackChunkName: "component---src-pages-moving-bit-js" */),
  "component---src-pages-my-account-js": () => import("./../../../src/pages/my-account.js" /* webpackChunkName: "component---src-pages-my-account-js" */),
  "component---src-pages-o-treino-js": () => import("./../../../src/pages/o-treino.js" /* webpackChunkName: "component---src-pages-o-treino-js" */),
  "component---src-pages-pacotes-e-planos-js": () => import("./../../../src/pages/pacotes-e-planos.js" /* webpackChunkName: "component---src-pages-pacotes-e-planos-js" */),
  "component---src-pages-pacotes-js": () => import("./../../../src/pages/pacotes.js" /* webpackChunkName: "component---src-pages-pacotes-js" */),
  "component---src-pages-politica-de-cookies-js": () => import("./../../../src/pages/politica-de-cookies.js" /* webpackChunkName: "component---src-pages-politica-de-cookies-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-r-js": () => import("./../../../src/pages/r.js" /* webpackChunkName: "component---src-pages-r-js" */),
  "component---src-pages-regressao-de-memoria-js": () => import("./../../../src/pages/regressao-de-memoria.js" /* webpackChunkName: "component---src-pages-regressao-de-memoria-js" */),
  "component---src-pages-reservas-js": () => import("./../../../src/pages/reservas.js" /* webpackChunkName: "component---src-pages-reservas-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */)
}

